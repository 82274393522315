import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/common/layout'
import LandingList from "../components/home/LandingList";
import {Container, Grid, Box} from "theme-ui";
import {Helmet} from "react-helmet";

export const query =graphql`
  query  {
    prismicHomepage {
      data {
        title {
          text
          html
        }
      }
    }
    allPrismicLanding(sort: {fields: first_publication_date, order: DESC}) {
      edges {
        node {
          data {
            title {
              html
              text
            }
          }
          id
          uid
        }
      }
    }
  }
`


const OdLandings = ({ data: {  allPrismicLanding }}, location) => {
  return(
      <Layout>
        <Helmet>
          <title>Landings</title>
          <meta name="robots" content="noindex"/>
        </Helmet>
        <Container>
          <Grid  gap={2} columns={[2, '2fr 2fr']}>
            <Box>
              <LandingList landings={allPrismicLanding.edges}/>
            </Box>
            <Box>
            </Box>
          </Grid>
        </Container>
      </Layout>
  )
};

export default OdLandings;



