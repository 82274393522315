import React, { Component } from "react";
import PropTypes from "prop-types";
import {Box, Flex, Grid, Themed, Button} from "theme-ui";
import {Link} from "gatsby";

export default class LandingList extends Component {
    render() {
        const { landings } = this.props
        const isBrowser = typeof window !== "undefined"
        const host = isBrowser ? window.location.host : [];

        return (
            <Flex sx={{flexDirection: 'column', justifyContent: `space-around`, textAlign: "center", width: '100%'}}>
                <Themed.h2>Landings disponibles</Themed.h2>
                {landings.map((landing,i) => {
                    const url = `${host + '/' + landing.node.uid}`
                    const iframeUrl = `<iframe url="{${host + '/' + landing.node.uid}"  id="ondemand" scrolling="no" frameborder="0" style="position: absolute; width: 100%; height:100%;  display: initial;" />`
                    return (
                        <Box key={i} sx={{variant:'styles.info', py: 2, px: 2, my: 2}}>
                            <Grid gap={2} columns={['4fr 2fr', '4fr 2fr']}>
                                <Box sx={{fontWeight: '900', py: 2}}>{landing.node.data.title.text}</Box>
                                <Box>
                                    <Link to={`${'/' + landing.node.uid}`} >
                                        <Button sx={{px: 2, py: 2, mr: 1}}>
                                            {/*<FaEye />*/} voir
                                        </Button>
                                    </Link>
                                    <Button sx={{px: 2, py: 2, mr: 1}} onClick={() =>  navigator.clipboard.writeText(url)}>
                                        url
                                    </Button>
                                    <Button sx={{px: 2, py: 2}} onClick={() =>  navigator.clipboard.writeText(iframeUrl)}>
                                        Iframe
                                    </Button>
                                </Box>
                            </Grid>
                        </Box>
                    )
                })}
            </Flex>
        )
    }
}


LandingList.propTypes = {
    landings: PropTypes.array.isRequired,
}